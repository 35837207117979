import { JwtCredentials, BAM } from '@bam/sdk';
import { toCamelCase, toSnakeCase } from '@bam/sdk/dist/src/common/converter';
import { createClient } from 'redis';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {
  setupCache,
  buildStorage,
  buildMemoryStorage,
  AxiosStorage
} from 'axios-cache-interceptor';
import localforage from 'localforage';
import { parseJwt } from '../utils/utils';
import orders from '../api/orders';
import account from '../api/account';
import base from '../api/base';
import { customRedisStorage, localStorage, invalidate } from '../utils/cache';

export default async function (
  { app, $auth, $axios, $config, _redirect },
  inject
) {
  $auth.onRedirect((to, _from) => {
    return app.localePath(to);
  });

  let storage: AxiosStorage;

  // Each client enables us to easier invalidate the cache later
  let localStore;
  let redisClient;

  if (process.server && $config.enableRedis) {
    redisClient = createClient({ url: `redis://${$config.redisHost}` });

    await redisClient.connect();
    storage = buildStorage(customRedisStorage(redisClient));
  } else if (!process.server) {
    localStore = localforage.createInstance({
      // List of drivers used
      driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
      // Prefix all storage keys to prevent conflicts
      name: 'BAM-cache'
    });
    storage = buildStorage(localStorage(localStore));
  } else {
    storage = buildMemoryStorage();
  }

  const baseApi = $axios.create({
    baseURL: `https://${$config.apiBaseUrl}/v1` || 'http://localhost:3000/v1/',
    headers: {}
  });

  // Create a custom axios instance
  const api = setupCache(baseApi, {
    storage,
    cacheTakeover: false,
    generateKey: (config: any) =>
      config.url + JSON.stringify(config.params ?? ''),
    cachePredicate: {
      statusCheck: (_status) => false // caching is by default disabled for now, it should be enabled after proper invalidation strategy is implemented
    }
  });

  api.interceptors.response.use(async function (response) {
    await invalidate(
      storage,
      response.config,
      redisClient,
      localStore,
      $config.enableRedis
    );

    return response;
  });

  registerCasingInterceptors(api);

  /**
   * Creating and injecting the BAM SDK for subsequent use
   */
  const sdkKey = 'BAM_SDK';
  let bamApi: BAM;
  if (!process.server && (await localStore.getItem(sdkKey)) != null) {
    const key = await localStore.getItem(sdkKey);

    bamApi = await BAM.build(key);
  } else {
    bamApi = new BAM(`https://${$config.apiBaseUrl}`);
  }
  // add an interceptor to the bam-client which injects the actual authorization token from nuxtjs-auth
  const authInterceptor = async (config) => {
    const tokenExpired = $auth?.strategy?.token?.status().expired();
    if (tokenExpired) {
      try {
        if ($auth.loggedIn) {
          await $auth.refreshTokens();
        }
        if ($auth.user?.guestId) {
          $auth.logout();
          return;
        }
      } catch (e) {
        $auth.logout();
      }
    }
    if ($auth.loggedIn || $auth.user?.guestId) {
      // TODO: Remove this entire interceptor in favour of using the in-built SDK interceptor.
      // The SDK authorize method should be called on login and start/stop impersonation.
      // We need to consider how/when to save/restore the token and refresh token from the SDK.
      const token = $auth.strategy.token.get();
      const refreshToken = $auth.strategy.refreshToken.get();
      let refreshTokenData;
      if (refreshToken) {
        refreshTokenData = refreshToken;
      }
      const credentials = new JwtCredentials({
        token: token.replace('Bearer ', ''),
        refreshToken: refreshTokenData
      });
      await bamApi.authorize(credentials);
      // Save the status of the API
      await localStore.setItem(sdkKey, bamApi.serialize());
    } else if (!process.server) {
      await localStore.removeItem(sdkKey);
    }

    return config;
  };

  bamApi.client.defaults.headers['Cache-Control'] = 'no-cache';
  bamApi.tenantClient.defaults.headers['Cache-Control'] = 'no-cache';

  bamApi.client.interceptors.request.use(authInterceptor);
  bamApi.tenantClient.interceptors.request.use(authInterceptor);

  const apiBase = base($config.apiBaseUrl);

  const services = {
    orders: orders(api, apiBase),
    account: account(api, apiBase),
  };
  // this function is used for refreshing tokens when authorization token expires
  // although it's not referenced anywhere
  createAuthRefreshInterceptor(api as any, async function (failedRequest) {
    try {
      await $auth.refreshTokens();
      const token = $auth.strategy.token.get();
      const tokenInfo = parseJwt(token);
      failedRequest.response.config.headers.Authorization = token;
      api.defaults.headers.common.Authorization = token;
      const temporalApi = $axios.create({
        baseURL:
          `https://${$config.apiBaseUrl}/v1` || 'http://localhost:3000/v1/'
      });
      temporalApi.defaults.headers.common.Authorization = token;
      const user = await temporalApi.get(
        `${apiBase.accountServiceUrl()}/user/${tokenInfo.id}`
      );
      await $auth.setUser({
        ...user.data,
        ...tokenInfo
      });
    } catch (e) {
      $auth.logout();
    }
  });
  // Inject to context as $api
  inject('api', api);
  inject('bamApi', bamApi);
  inject('isProduction', $config.env === 'production');
  inject('showLogin', $config.features.showLogin);
  inject('showCrypto', $config.features.showCrypto);
  inject('defaultPaymentProvider', $config.features.defaultPaymentProvider);
  inject('showTickets', $config.features.showTickets);
  inject('showSecondary', $config.features.showSecondary);
  inject('mainOrganizer', $config.features.mainOrganizer);
  inject('enableOrganizers', $config.features.enableOrganizers);
  inject('requirePhoneVerification', $config.features.requirePhoneVerification);
  inject('enableMetaTracking', $config.features.enableMetaTracking);
  inject('metaPixelId', $config.features.metaPixelId);
  inject('showReportsDashboard', $config.features.showReportsDashboard);
  inject('showNftCollectibles', $config.features.showNftCollectibles);
  inject('featuredEvents', $config.features.featuredEvents);
  inject('pdfTemplates', $config.features.pdfTemplates);
  inject(
    'showOrganizerReportsDashboard',
    $config.features.showOrganizerReportsDashboard
  );
  inject('services', services);
  inject('maximalRequestBodyLength', $config.maximalRequestBodyLength);
}

function registerCasingInterceptors(api) {
  api.interceptors.request.use(function (config) {
    if (
      !config.headers['Content-Type'] ||
      config.headers['Content-Type'] === 'application/json'
    ) {
      config.data = toSnakeCase(config.data);
      config.params = toSnakeCase(config.params);
    }
    return config;
  });

  api.interceptors.response.use(function (response) {
    if (
      ['stream', 'blob', 'arraybuffer'].includes(response.config.responseType)
    ) {
      return response;
    }

    return {
      ...response,
      data: toCamelCase(response.data)
    };
  });
}
