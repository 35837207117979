import { parseJwt } from '../utils/utils';

export default ($api, base) => ({
  async identifyWallet(secret, tenant) {
    return (
      await $api.$post(`${base.websocketServiceUrl(tenant)}/adhoc`, {
        secret
      })
    ).data;
  },
  async getUser(id) {
    return (await $api.$get(`${base.accountServiceUrl()}/user/${id}`)).data;
  },
  async updateToken($auth) {
    if (!$auth.state.user.impersonated_by) {
      await $auth.refreshTokens();
    }
    const token = $auth.strategy.token.get();
    const tokenInfo = parseJwt(token);

    $api.setHeader('Authorization', token);
    const user = await this.getUser(tokenInfo.id);
    await $auth.setUser({
      ...tokenInfo,
      ...user
    });
    return user;
  },
  async refreshToken($auth, organizerId) {
    const oldToken = localStorage.getItem('auth._refresh_token.local');
    const res = await $api.post(`${base.accountServiceUrl()}/auth/refresh`, {
      refresh_token: oldToken,
      organizerId
    });
    await $auth.setUserToken(
      'Bearer ' + res?.data?.data?.token,
      res?.data?.data?.refreshToken
    );
    const tokenInfo = parseJwt(res.data.data.token);
    const user = await this.getUser(tokenInfo.id);
    await $auth.setUser({
      ...tokenInfo,
      ...user
    });
    $api.setHeader('Authorization', 'Bearer ' + res?.data?.data?.token);
  },
  async updateTokenImpersonate($auth, token) {
    const tokenInfo = parseJwt(token);
    const user = await this.getUser(tokenInfo.id);
    // Handle cases where the user is already impersonated
    // and we just need to refresh the token
    // Otherwise, the impersonate request is called with the impersonated users' token
    if (!localStorage.getItem('impersonated_by_token')) {
      const currentToken = $auth.strategy.token.get();
      localStorage.setItem('impersonated_by_token', currentToken);
      localStorage.setItem(
        'impersonated_by_refresh_token',
        $auth.strategy.refreshToken.get()
      );
    }
    $auth.setUser({
      ...user,
      ...tokenInfo
    });
    $api.setHeader('Authorization', `Bearer ${token}`);

    $auth.strategy.token.set(token);

    return user;
  },
  async stopImpersonate($auth) {
    const token = localStorage.getItem('impersonated_by_token');
    const refreshToken = localStorage.getItem('impersonated_by_refresh_token');
    localStorage.removeItem('impersonated_by_token');
    localStorage.removeItem('impersonated_by_refresh_token');
    localStorage.setItem('auth._refresh_token.local', refreshToken);
    $api.setHeader('Authorization', `${token}`);

    const tokenInfo = parseJwt(token);
    const user = await this.getUser(tokenInfo.id);
    await $auth.setUser({
      ...tokenInfo,
      ...user,
    });
    await $auth.strategy.token.set(token);

    return user;
  },
  async impersonate(payload) {
    return await $api.$post(`${base.accountServiceUrl()}/auth/impersonate`, payload, {
      headers: {
        Authorization: payload.token
      }
    });
  },

});
